import React from 'react';
import logo from './logo.svg';
import './App.css';
import LandingPage from './LandingPage';

function App() {
  return (
    <LandingPage></LandingPage>
    // <div className="App">
    //   {/* <div className="nav">
    //     LOGO
    //     <ul>
    //       <li><a href="default.asp">Dex</a></li>
    //       <li><a href="news.asp">Bird</a></li>
    //       <li><a href="contact.asp">Telegram</a></li>
    //       <li><a href="about.asp">Buy</a></li>
    //       <li><a href="about.asp">Updates</a></li>
    //     </ul>
    //     </div> */}
    // </div>
  );
}

export default App;
